.hero-hero-container {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  box-shadow: 5px 5px 15px 10px #bebebe;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: flex-end;
  background-color: #000000;
}
.hero-container {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
}
.hero-i-phone-container {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  position: relative;
  align-items: flex-end;
  border-radius: 38px;
  margin-bottom: -100px;
  animation-name: fadeInUp;
  animation-delay: 0s;
  justify-content: center;
  animation-duration: 1000ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}
.hero-whiteshadow {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  box-shadow: 5px 5px 100px 0px rgba(255, 255, 255, 0.5);
  border-radius: 40px;
  animation-name: fadeIn;
  animation-delay: 1.2s;
  animation-duration: 1200ms;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}
.hero-iphone {
  width: 250px;
  z-index: 1;
  object-fit: cover;
}
.hero-app {
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: 100%;
  position: absolute;
  object-fit: cover;
  border-radius: 40px;
}
.hero-text1 {
  color: #ffffff;
  display: inline-block;
  opacity: 0;
  animation: forwards;
  font-size: 20px;
  text-align: center;
  font-family: "Lexend";
  line-height: 1.25;
  animation-name: fadeIn;
  animation-delay: 1.2s;
  animation-duration: 1000ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}
.hero-text6 {
  color: #ffffff;
  display: inline-block;
  opacity: 0;
  font-size: 72px;
  text-align: center;
  font-family: "Passion One";
  animation-name: fadeIn;
  letter-spacing: 1.5px;
  animation-delay: 1s;
  animation-duration: 1000ms;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.heroroot-class-name {
  height: auto;
}
@media(max-width: 991px) {
  .hero-hero-container {
    height: auto;
  }
}
@media(max-width: 767px) {
  .hero-hero-container {
    height: auto;
  }
}
@media(max-width: 479px) {
  .hero-hero-container {
    height: auto;
  }
  .hero-container {
    width: 100%;
    align-items: center;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    justify-content: center;
  }
  .hero-text6 {
    text-align: center;
  }
  .heroroot-class-name {
    height: auto;
  }
}
