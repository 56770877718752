.footer-footer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  background-color: #0f172a;
}
.footer-container1 {
  flex: 1;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
}
.footer-container2 {
  flex: 1;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
}
.footer-container3 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer-link1 {
  text-decoration: none;
}
.footer-link2 {
  text-decoration: none;
}
.footer-text1 {
  color: #64748b;
  display: inline-block;
  font-style: normal;
  font-family: "Lexend";
  font-weight: 700;
}
.footer-text2 {
  color: rgb(100, 116, 139);
  display: inline-block;
  font-style: normal;
  font-family: "Lexend";
  font-weight: 700;
}
.footer-text3 {
  color: rgb(100, 116, 139);
  display: inline-block;
  font-style: normal;
  font-family: "Lexend";
  font-weight: 700;
}


@media(max-width: 767px) {
  .footer-container1 {
    display: none;
  }
  .footer-container2 {
    justify-content: flex-start;
  }
}
@media(max-width: 479px) {
  .footer-text1 {
    color: rgb(100, 116, 139);
    font-style: normal;
    font-family: Lexend;
    font-weight: 700;
  }
}
